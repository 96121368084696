import {
	DeleteFeatureFlagResponse,
	FeatureFlagsResponse,
	FeatureFlagsResponsePaginated,
	UpdateFeatureFlagResponse,
	UserAssociationResponse,
} from "common/types/Admin";
import { ListResponse, ListRolesResponse } from "common/types/Monarch";
import { UserEventsResponse } from "../organizations/UserEventsAuditGrid";
import {
	Organization,
	OrganizationAssociation,
} from "../organizations/utils/OrganizationUtils";
import MonarchClient from "./MonarchClient";

class AdminClient {
	static async getOrganizations(
		searchString: string,
		page?: string
	): Promise<ListResponse> {
		page = page ? page : "1";
		return MonarchClient.get(
			`organizations?search_string=${searchString}&size=100&page=${page}`
		).then((res) => res.json());
	}

	static async getMyOrganizations(): Promise<Array<Organization>> {
		return MonarchClient.get(`auth/my-organizations`).then((res) => res.json());
	}

	static async getOrganization(id: number): Promise<Organization> {
		return MonarchClient.get(`organizations/organization/${id}`).then((res) =>
			res.json()
		);
	}

	static async createOrganization(organization: Organization) {
		return MonarchClient.post("organizations/organization", {
			json: organization,
		}).then((res) => res.json());
	}

	static async updateOrganization(id: number, organization: Organization) {
		return MonarchClient.put(`organizations/organization/${id}`, {
			json: organization,
		}).then((res) => res.json());
	}

	static async addUserToOrganization(username: string, organizationId: number) {
		return MonarchClient.put(
			`organizations/organization/${organizationId}/user/${username}`
		).then((res) => res.json());
	}

	static async getOrganizationFeatureFlags(
		organizationId: string | number
	): Promise<FeatureFlagsResponsePaginated> {
		return MonarchClient.get(
			`organizations/organization/${organizationId}/featureflags`
		).then((res) => res.json());
	}

	static async updateOrganizationFeatureFlags(
		organizationId: string | number,
		feature: string
	): Promise<UpdateFeatureFlagResponse> {
		return MonarchClient.put(
			`organizations/organization/${organizationId}/featureflags/featureflag/${feature}`
		).then((res) => res.json());
	}

	static async deleteOrganizationFeatureFlags(
		organizationId: string | number,
		feature: string
	): Promise<DeleteFeatureFlagResponse> {
		return MonarchClient.delete(
			`organizations/organization/${organizationId}/featureflags/featureflag/${feature}`
		).then((res) => res.json());
	}

	static async getEndorsements(organizationId: number): Promise<any[]> {
		return MonarchClient.get(
			`licensing/organization_id/${organizationId}`
		).then((res) => res.json());
	}

	static async getAvailableEndorsements(): Promise<any[]> {
		return MonarchClient.get(`licensing/endorsements`).then((res) =>
			res.json()
		);
	}

	static async createEndorsement(organizationId: number, endorsement: string) {
		return MonarchClient.post(
			`licensing/organization_id/${organizationId}/endorsement/${endorsement}`
		).then((res) => res.json());
	}

	static async removeEndorsement(organizationId: number, endorsement: string) {
		return MonarchClient.delete(
			`licensing/organization_id/${organizationId}/endorsement/${endorsement}`
		).then((res) => res.json());
	}

	static async getUsers(searchString: string): Promise<ListResponse> {
		return MonarchClient.get(
			`users?search_string=${encodeURIComponent(searchString)}`
		).then((res) => res.json());
	}

	static async createUser(
		organizationId: number,
		username: string,
		nickname: string
	) {
		return MonarchClient.put(
			`organizations/organization/${organizationId}/user/${username}?nickname=${nickname}`
		).then((res) => res.json());
	}

	static async disassociateUser(organizationId: number, username: string) {
		return MonarchClient.delete(
			`organizations/organization/${organizationId}/user/${username}`
		).then((res) => res.json());
	}

	static async createAuth0User(email: string, orgName: string) {
		return MonarchClient.post("auth/auth0/create-user", {
			json: {
				email,
				user_metadata: {
					registered: false,
					organization: orgName,
				},
			},
		}).then((res) => res.json());
	}

	static async sendResetPasswordEmail(email: string) {
		return MonarchClient.post(`auth/auth0/change-password/${email}`).then(
			(res) => (res.status !== 204 ? res.json() : {})
		);
	}

	// TODO: better return type for API endpoint users/user/$username/roles
	// maybe Promise<Array<UserRole>> ??
	static async getUserRoles(username: string): Promise<any> {
		return MonarchClient.get(`users/user/${username}/roles`).then((res) =>
			res.json()
		);
	}

	static async deleteUserRole(username: string, role: string) {
		return MonarchClient.delete(
			`users/user/${username}/roles/role/${role}`
		).then((res) => res);
	}

	static async addUserRole(username: string, role: string) {
		return MonarchClient.post(`users/user/${username}/roles/role/${role}`).then(
			(res) => res
		);
	}

	static async deleteOrganization(orgId: number) {
		return MonarchClient.delete(`organizations/organization/${orgId}`).then(
			(res) => res
		);
	}

	static async getAvailableRoles(): Promise<ListRolesResponse> {
		return MonarchClient.get(`users/list-available-roles`).then((res) =>
			res.json()
		);
	}

	static async updateNickname(username: string, nickname: string) {
		return MonarchClient.put(
			`users/user/${username}/nickname/${nickname}`
		).then((res) => res);
	}

	static async getUserOrganizationAssociations(
		username: string
	): Promise<OrganizationAssociation[]> {
		return MonarchClient.get(
			`users/user/${username}/organization-associations`
		).then((res) => res.json());
	}

	static async getOrgUserEvents(
		searchParams: URLSearchParams
	): Promise<UserEventsResponse> {
		return MonarchClient.get(
			`organizations/my/user-events?${searchParams.toString()}`
		).then((res) => res.json());
	}

	static async getUserEvents(
		searchParams: URLSearchParams
	): Promise<UserEventsResponse> {
		let username = searchParams.get("username");
		searchParams.delete("username");
		return MonarchClient.get(
			`users/user/${username}/user-events?${searchParams.toString()}`
		).then((res) => res.json());
	}

	static async getUserEventTypes(): Promise<string[]> {
		return MonarchClient.get(`users/event-types`).then((res) => res.json());
	}

	static async getFeatureFlags(): Promise<FeatureFlagsResponse> {
		return MonarchClient.get(`featureflags`).then((res) => res.json());
	}

	static async getFeatureFlagsUser(
		username
	): Promise<FeatureFlagsResponsePaginated> {
		return MonarchClient.get(`users/user/${username}/featureflags`).then(
			(res) => res.json()
		);
	}

	static async deleteFeatureFlags(
		username,
		feature
	): Promise<DeleteFeatureFlagResponse> {
		return MonarchClient.delete(
			`users/user/${username}/featureflags/featureflag/${feature}`
		).then((res) => res.json());
	}

	static async updateFeatureFlags(
		username,
		feature
	): Promise<UpdateFeatureFlagResponse> {
		return MonarchClient.put(
			`users/user/${username}/featureflags/featureflag/${feature}`
		).then((res) => res.json());
	}

	static async deactivateUser(username: string) {
		return MonarchClient.delete(`users/deactivate-user/${username}`).then(
			(res) => res
		);
	}

	static async getUserAssociations(): Promise<UserAssociationResponse> {
		return MonarchClient.get(`users/my/associations`).then((res) => res.json());
	}
}

export default AdminClient;
