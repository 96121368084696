import type { IUser } from "common/utils/UserUtils";
import { logoutRedirect } from "modules/auth/AuthUtils";
import AdminClient from "modules/facade/AdminClient";
import { atom, map } from "nanostores";

export const isLeftRailOpen = atom(true);

export const user = map<IUser>({
	username: "",
	gateway: "",
	organization: "",
	organization_id: "",
	deprecated_flagstaff_org_id: null,
	endorsements: [],
	roles: [],
	traits: {},
	sub: "",
	exp: "",
	picture: "",
	feature_flags: [],
});

const checkUserValues = async () => {
	const facadeUser = await AdminClient.getUserAssociations();
	const cookieRoles = user.value.roles.sort();
	const cookieCurrentOrg = Number(user.value.organization_id);
	const dbOrgs =
		facadeUser.organizations?.map((item) => item.organization) || [];
	const dbRoles =
		facadeUser.roles?.map((item) => item.permissions).flat() || [];
	const uniqueRoles = Array.from(new Set(dbRoles)).sort();
	const isOpteraAdmin = uniqueRoles.includes("optera:admin");

	if (
		(!isOpteraAdmin && !dbOrgs.includes(cookieCurrentOrg)) ||
		cookieRoles.toString() !== uniqueRoles.toString()
	) {
		alert("Your session has expired. Redirecting to login page...");
		logoutRedirect("logout", "/", "/");
	}
};

user.listen((newUser, oldUser) => {
	if (newUser.username && !oldUser.username) {
		setInterval(() => {
			checkUserValues();
		}, 600000);
	}
});
