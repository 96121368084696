import ky, { KyResponse, Options } from "ky";
import { loginRedirect } from "modules/auth/AuthUtils";
import { publicPaths } from "modules/auth/RouteGuard";
import Router from "next/router";
import * as Sentry from "@sentry/browser";
import { msg } from "common/stores/SnackbarStore";

/**
 * Base class for Monarch server implementations. In implementations, please use the functions
 * defined here to make requests to the backend.  Each function checks whether a monarch URL
 * exists, if it does not, it retrieves it, then proceeds with the request. If you would like
 * to use request methods aside from get/post/put, please add them here.
 */

function handleError(error): never {
	if (!error) {
		console.error("MonarchClient handleError called with no error");
		throw error;
	}
	if (error.name === "AbortError") {
		console.warn("Fetch aborted");
		throw error;
	} else if (error.name === "HTTPError") {
		console.warn(
			"MonarchClient handleError encountered HTTPError",
			error.toString()
		);
		if (error.response?.status === 401 && typeof window !== "undefined") {
			// if this error happened on server side function throw it back
			// and expect it to be handled by the function that called it.
			// as a redirect might not be desired behavior
			const path = Router.asPath.split("?")[0];
			// TODO: if the Router already has a returnUrl query param don't add another or muck it up
			let query;
			const urlParams = new URLSearchParams(Router.asPath.split("?")[1]); // Router.query on SingletonRouter isn't good to use. on router from useRouter it would be fine
			if (!publicPaths.includes(path)) {
				query = { returnUrl: path };
			}
			const errorParam = urlParams.get("error");
			// allow for params to pass from backend to login page
			if (path.includes("survey")) {
				// Router.query does not seem to be working so we're splitting router.path
				query = {
					returnUrl: `${path}?replyId=${
						urlParams.get("replyId") ?? localStorage.getItem("replyId")
					}`,
					username: urlParams.get("username"),
				};
			}
			// originally for Passwordless case when link is either expired or invalidated from already being used - maybe can be deleted?
			if (Router.asPath.includes("unauthorized")) {
				query = {
					returnUrl: path,
				};
			}
			loginRedirect(
				"auth0/login",
				query.returnUrl,
				`/${errorParam ? `?error=${new URLSearchParams(errorParam)}` : ""}`,
				query.username
			);
			// is this the best return value here?
			throw error;
		}
	}

	// re-throw so the caller can handle the error
	throw error;
}

export const swrErrorHandle = (data, error, isLoading, errorMessage) => {
	if (!isLoading && !error && data && !Array.isArray(data)) {
		const err = new Error(errorMessage);
		console.error(err.toString());
		Sentry.captureException(err);
		msg.set({
			message: errorMessage,
			autoHide: false,
			severity: "error",
		});
	}
};

export default class MonarchClient {
	static api = ky.extend({
		prefixUrl: process.env.MONARCH_URL,
		credentials: "include",
		timeout: 60 * 1000,
	});

	static async get(
		endpoint: string | URL,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.api
			.get(endpoint, options)
			.catch((e) => handleError(e));
	}

	static async post(
		endpoint: string | URL,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.api
			.post(endpoint, options)
			.catch((e) => handleError(e));
	}

	static async put(
		endpoint: string | URL,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.api
			.put(endpoint, options)
			.catch((e) => handleError(e));
	}

	static async delete(
		endpoint: string | URL,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.api
			.delete(endpoint, options)
			.catch((e) => handleError(e));
	}

	static async patch(
		endpoint: string | URL,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.api
			.patch(endpoint, options)
			.catch((e) => handleError(e));
	}
}
